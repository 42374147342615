import React from 'react';
import commonStyle from 'components/common.module.css';
import cx from 'clsx';

import style from './SignUp.module.css';

const LoginLink = () => {
  return (
    <div className={cx(commonStyle.centerLink, style.loginLink)}>
      Already have an account?{' '}
      <a className={cx(commonStyle.link, commonStyle.link_blue)} href={'/login'}>
        Log In
      </a>
    </div>
  );
};

export default LoginLink;
