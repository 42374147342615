import React from 'react';
import Loader from 'components/Loader';

import style from './Button.module.css';

interface Props {
  variant?: 'default' | 'border';
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  onClick?: (event: any) => void;
  type?: 'button' | 'submit' | 'reset' | undefined;
}

const Button: React.FC<Props> = ({
  variant = 'default',
  disabled = false,
  loading = false,
  className,
  onClick = undefined,
  children,
  type = 'button',
  ...rest
}) => {
  const unActive = disabled || loading;

  return (
    <button
      type={type}
      onClick={unActive ? undefined : onClick}
      disabled={unActive}
      className={`${style.button} ${unActive ? style.disabled : ''} ${
        variant === 'border' ? style.border : ''
      } ${className}`}
      {...rest}
    >
      {loading ? <Loader className={style.loader} /> : children}
    </button>
  );
};

export default Button;
