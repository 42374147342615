import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {unwrapResult} from '@reduxjs/toolkit';
import Button from 'components/Button';
import MobileCodeCommon from 'components/MobileCodeCommon/MobileCodeCommon';
import {backToPrevScreen, enterCode, selectScreen} from 'auth/authSlice';
import {EnterCodeScreen} from 'auth/authApi';
import commonStyle from 'components/common.module.css';
import {useAppSelector} from 'hooks';
import {maskEmail} from 'utils/maskEmailOrPhone';
import cx from 'clsx';

import {isPasswordLessAuth} from 'auth/auth0';

import style from './Verification.module.css';

const VerificationCode = () => {
  const dispatch = useDispatch();
  const screen = useAppSelector(selectScreen);

  const [code, setCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [loading, setLoading] = useState(false);

  const {email = '', resend = () => {}, backScreen} = screen as EnterCodeScreen;

  const handleClickResend = () => {
    setCode('');
    setErrorMessage('');
    resend();
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const result = await dispatch(
        enterCode({screen: screen as EnterCodeScreen, code})
      );
      // TODO: fix dispatch typings
      // @ts-ignore
      unwrapResult(result);
    } catch (e) {
      const errorText =
        e.message === 'Wrong email or verification code.'
          ? 'Wrong verification code.'
          : e.message;
      setErrorMessage(errorText);
    }

    setLoading(false);
  };

  const handleClickBack = () => {
    if (backScreen) {
      dispatch(backToPrevScreen({backScreen}));
    }
  };

  useEffect(
    () => {
      if (code.length === 6) {
        handleSubmit();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [code]
  );

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
      className={commonStyle.loginForm}
    >
      <div className={cx(commonStyle.title, style.title_desktop)}>Welcome</div>
      <div className={cx(commonStyle.title, style.title_mobile)}>
        Security code
      </div>
      {isPasswordLessAuth(email) && (
        <div className={cx(commonStyle.text, commonStyle.marginBottom)}>
          We have sent a message with a one-time security code to{' '}
          {maskEmail(email)}
        </div>
      )}
      <MobileCodeCommon
        type='LOGIN'
        onResend={handleClickResend}
        value={code}
        onChange={setCode}
        errorMessage={errorMessage}
        hideResend={!isPasswordLessAuth(email)}
      />
      <Button
        disabled={code.length !== 6}
        loading={loading}
        className={cx(commonStyle.button, style.submitButton)}
        type='submit'
      >
        Log in
      </Button>
      {backScreen && (
        <Button
          className={cx(commonStyle.button, commonStyle.marginTop)}
          onClick={handleClickBack}
          variant='border'
        >
          Back
        </Button>
      )}
    </form>
  );
};

export default VerificationCode;
