import {PaletteMode} from '@mui/material';
import {ThemeOptions} from '@mui/material/styles';

import palette from './palette';
import spacing from './spacing';
import typography from './typography';
import shape from './shape';

export type Settings = {
  mode: PaletteMode;
};

const themeOptions = (settings: Settings): ThemeOptions => {
  const {mode} = settings;

  return {
    palette: palette(mode),
    ...shape,
    ...spacing,
    ...typography,
  };
};

export default themeOptions;
