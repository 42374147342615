import React, {ElementType, SVGProps} from 'react';

import style from '../Header.module.css';

import { ReactComponent as MissionIcon } from '../icons/mission.svg';
import { ReactComponent as AboutIcon } from '../icons/about-us.svg';
import{ ReactComponent as ContactIcon } from '../icons/contact-us.svg';

type RouteParams = {
  label: string;
  Icon: ElementType<SVGProps<SVGSVGElement>>;
  to: string;
};

export const routes: RouteParams[] = [
  {
    label: 'Why Ennabl',
    Icon: MissionIcon,
    to: 'https://www.ennabl.com/why-ennabl',
  },
  {
    label: 'About Us',
    Icon: AboutIcon,
    to: 'https://www.ennabl.com/about/team',
  },
  {
    label: 'Contact Us',
    Icon: ContactIcon,
    to: 'https://www.ennabl.com/about/contact-us',
  },
]

const Links = () => {
  return (
    <>
      {routes.map(route => (
        <a href={route.to} className={style.navItem} key={route.to}>
          <route.Icon />
          {route.label}
        </a>
      ))}
    </>
  );
};

export default Links;