import LogRocket from 'logrocket';

import type {AnalyticsAdapter} from './types';

export class LogRocketAnalytics implements AnalyticsAdapter {
  public init(appId: string, options?: {release?: string}) {
    LogRocket.init(appId, options);
  }

  public identify(userData: {id: string} & Record<string, any>) {
    const {id, ...data} = userData;
    LogRocket.identify(id, data);
  }

  public track(eventName: string, eventProperties: Record<string, any>) {
    LogRocket.track(eventName, eventProperties);
  }
}
