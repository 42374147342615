import React from 'react';
import {useDispatch} from 'react-redux';
import {unwrapResult} from '@reduxjs/toolkit';
import {Field, Form} from 'react-final-form';
import {FORM_ERROR} from 'final-form';
import {isValidEmail} from 'utils/validators';
import TextField from 'components/form/TextField';
import Button from 'components/Button';
import {useAppSelector} from 'hooks';
import {login, selectScreen} from 'auth/authSlice';
import {EnterEmailScreen} from 'auth/authApi';
import commonStyle from 'components/common.module.css';
import cx from 'clsx';

import {ReactComponent as MailSvg} from 'components/icons/mail.svg';

import style from './FirstTimeAuth.module.css';

const FirstTimeAuth = () => {
  const dispatch = useDispatch();
  const screen = useAppSelector(selectScreen);

  const initialValues = {
    email: '',
  };

  const validate = (values: typeof initialValues) => {
    const errors: any = {};
    if (!values.email) {
      errors.email = 'Required';
    }
    if (values.email && !isValidEmail(values.email)) {
      errors.email = 'Invalid email address';
    }
    return errors;
  };

  const onSubmit = async ({email}: typeof initialValues) => {
    try {
      const result = await dispatch(
        login({
          screen: screen as EnterEmailScreen,
          email
        })
      );
      // TODO: fix dispatch typings
      // @ts-ignore
      unwrapResult(result);
    } catch (e: any) {
      return {
        [FORM_ERROR]: e.message === 'Blocked user.' || e.code === 'bad.connection' || e.code === 'bad.email'
          ? 'Invalid email address'
          : e?.message || e?.error_description
      }
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validate}
      render={({handleSubmit, submitting, pristine, dirtySinceLastSubmit, hasSubmitErrors, hasValidationErrors, submitError}) => (
        <form onSubmit={handleSubmit} className={commonStyle.loginForm}>
          <div className={commonStyle.title}>Welcome</div>
          <div className={commonStyle.subtitle}>
            Please enter your email to <b>Log in</b>
          </div>
          <div className={style.formWrapper}>
            <Field
              name='email'
              component={TextField}
              variant='filled'
              label='E-mail'
              type='email'
              fullWidth
              helperTextRequired={true}
              formErrorText={submitError && !dirtySinceLastSubmit ? submitError : ''}
              maxLength={50}
              InputProps={{
                startAdornment: (
                  <MailSvg width={16} height={16} />
                ),
              }}
            />
          </div>

          {/*{submitError && !dirtySinceLastSubmit && <p className={commonStyle.error}>{submitError}</p>}*/}
          <Button
            disabled={pristine || (!dirtySinceLastSubmit && hasSubmitErrors) || hasValidationErrors }
            loading={submitting}
            className={cx(commonStyle.button, style.button)}
            type='submit'
          >
            Next
          </Button>
        </form>
      )}
    />
  );
};

export default FirstTimeAuth;
