import React, {useEffect} from 'react';
import {Route} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import HeadLegend from 'components/HeadLegend';
import appStyle from 'App.module.css';
import {useAppSelector} from 'hooks';
import {selectScreen, startAuth} from 'auth/authSlice';
import Loader from 'components/Loader';
import cx from 'clsx';

import {SIGN_UP} from 'core/routes';

import {Helmet} from 'react-helmet';

import VerificationCode from './VerificationCode';
import style from './SignUp.module.css';


const SignUp = () => {
  const dispatch = useDispatch();
  const screen = useAppSelector(selectScreen);

  useEffect(() => {
    if (screen.screenType === 'ENTRY_SCREEN') {
      dispatch(startAuth(true));
    }
  }, [screen.screenType, dispatch]);

  const isLoading = ['ENTRY_SCREEN', 'AUTHORIZED'].includes(screen.screenType)

  return (
    <div className={cx(
      appStyle.routesContainer,
      isLoading ? style.loginSignUpContainer : style.signUpContainer)
    }>
      <Helmet>
        <title>Sign Up - Ennabl</title>
      </Helmet>

      {isLoading ? (
        <div className={appStyle.loaderContainer}>
          <Loader />
        </div>
      ) : (
        <>
          <HeadLegend />
          <Route
            exact
            path={SIGN_UP}
            component={VerificationCode}
          />
        </>
      )}
    </div>
  );
};

export default SignUp;
