import * as auth0 from 'auth0-js';
import {v4 as uuidv4} from 'uuid';

import {
  Authorized,
  EnterCodeScreen,
  Screen,
  VerificationMethod,
  EntryScreen,
  EnterEmailScreen,
} from './authApi';
import {
  AUTH0_DB_CONNECTION,
  DEFAULT_APP_AUTHORIZED_REDIRECT_URI,
  BASE_URL,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_AUDEINCE,
  APP_LOGOUT_URL,
  APP_SIGNUP_URI,
} from '../config';

const DEFAULT_AUTHORIZED_URI = `${BASE_URL}/?authorized=true`;
const MOBILE_NATIVE_REDIRECT_PREFIX = 'com.ennabl.auth0://';

interface TokenData {
  accessToken: string;
}

const params = new URLSearchParams(window.location.search);
const state = params.get('state') || uuidv4();
const authorized = params.get('authorized') || 'false';
const forceLogout = params.get('logout') || 'false';
const scope = params.get('scope') ?? 'openid profile email';
const authRedirectUri = buildRedirectUri();
const isMobileNative = authRedirectUri.startsWith(
  MOBILE_NATIVE_REDIRECT_PREFIX
);

const authProps: auth0.AuthOptions = {
  clientID: AUTH0_CLIENT_ID,
  domain: AUTH0_DOMAIN,
  responseType: 'code',
  state,
  audience: AUTH0_AUDEINCE,
  scope,
  redirectUri: isMobileNative ? authRedirectUri : DEFAULT_AUTHORIZED_URI,
};

const webAuth = new auth0.WebAuth(authProps);

export function entryScreen(isSignUp = false): EntryScreen | Authorized {
  if (authorized === 'true' && window.location.href !== authRedirectUri) {
    redirectToApp(authRedirectUri);

    return {
      screenType: 'AUTHORIZED',
    };
  }

  if (isSignUp) {
    return {
      screenType: 'ENTRY_SCREEN',
      start: startSignup,
    };
  } else {
    return {
      screenType: 'ENTRY_SCREEN',
      start: startAuth,
    };
  }
}

export async function startAuth(): Promise<Screen> {
  if (forceLogout === 'true') {
    await logout();
    return {
      screenType: 'ENTRY_SCREEN',
    };
  }

  const tokenOrNull = isMobileNative ? null : await getTokenDataOrNull();

  if (tokenOrNull?.accessToken) {
    redirectToApp(authRedirectUri);
    return {
      screenType: 'AUTHORIZED',
    };
  }

  return goToEnterEmailScreen({
    method: 'EMAIL',
    device: {
      deviceStatus: 'KNOWN_DEVICE',
    },
  });
}

export async function startSignup(): Promise<Screen | EnterCodeScreen> {
  const email = params.get('email');

  if (!email) {
    window.location.replace(window.location.origin);
    return {
      screenType: 'ENTRY_SCREEN',
    };
  }

  return goToEnterCodeScreen(
    {
      device: {
        deviceStatus: 'UNKNOWN_DEVICE',
        email,
      },
      method: 'EMAIL',
    },
    undefined,
    true
  );
}

export async function CreateUser() {
  return async () => {
    try {
      const token = await getToken();
      const response = await signup(token);
      if (response.status === 403) {
        const responseJson = await response.json();
        if (responseJson) throw responseJson;
        else throw await response.text();
      }

      redirectToApp(authRedirectUri);
      return {
        screenType: 'AUTHORIZED',
      };
    } catch (e: any) {
      if (e.error_description) throw e.error_description;
      else throw e;
    }
  };
}

async function getTokenDataOrNull(): Promise<TokenData | null> {
  try {
    const token = await getToken();
    return {accessToken: token};
  } catch (e: any) {
    return null;
  }
}

async function getToken(): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    webAuth.checkSession(
      {
        responseType: 'token',
        nonce: params.get('nonce') ?? undefined,
      },
      (error, result) => {
        if (error) {
          return reject(error);
        }
        return resolve(result.accessToken);
      }
    );
  });
}

interface Ctx {
  device: DeviceInfo;
  method?: VerificationMethod;
  errorMessage?: string;
}

async function goToEnterCodeScreen(
  ctx: Ctx,
  backScreen?: Promise<Screen>,
  isSignUp = false
): Promise<EnterCodeScreen> {
  let connection: string;
  let authInfo: {email?: string};

  if (ctx.method === 'EMAIL') {
    connection = 'email';
    authInfo = {email: ctx.device.email!};
  } else {
    throw new Error(`Unknown verification method: ${ctx.method}`);
  }

  let authParams: any = {state};

  const sendCode = async (ctx: Ctx) => {
    return new Promise<EnterCodeScreen>((resolve, reject) => {
      const enterCodeScreen: EnterCodeScreen = {
        screenType: 'ENTER_CODE',
        backScreen,
        method: ctx.method!,
        ...authInfo,
        error: ctx.errorMessage,
        enter,
        resend: () => sendCode(ctx),
      };

      if (isPasswordLessAuth(ctx.device.email)) {
        webAuth.passwordlessStart(
          {
            connection,
            authParams,
            send: 'code',
            ...authInfo,
          },
          error => {
            if (error) {
              if (isSignUp) {
                window.location.replace(window.location.origin);
              }
              return reject(error);
            }
            return resolve(enterCodeScreen);
          }
        );
      } else {
        resolve(enterCodeScreen);
      }
    });
  };

  const enter = async (code: string) => {
    return new Promise<Screen>((resolve, reject) => {
      const onErrorCallback = (error: auth0.Auth0Error | null) => {
        if (error) {
          reject(error.error_description);
          return resolve({screenType: 'AUTH_FAILED'});
        }
        return resolve({screenType: 'AUTHORIZED'});
      };

      if (isPasswordLessAuth(ctx.device.email)) {
        webAuth.passwordlessLogin(
          {
            connection,
            verificationCode: code,
            ...authInfo,
            state: params.get('source_state') ?? state,
          },
          onErrorCallback
        );
      } else {
        webAuth.login(
          {
            email: ctx.device.email!,
            password: code,
            realm: 'Username-Password-Authentication',
            state: params.get('source_state') ?? state,
          },
          onErrorCallback
        );
      }
    });
  };

  return sendCode(ctx);
}

async function goToEnterEmailScreen(ctx: Ctx): Promise<EnterEmailScreen> {
  return {
    screenType: 'ENTER_EMAIL_SCREEN',
    login: email =>
      goToEnterCodeScreen({
        ...ctx,
        device: {
          email,
          deviceStatus: 'KNOWN_DEVICE',
        },
      }),
  };
}

interface DeviceInfo {
  deviceStatus: 'KNOWN_DEVICE' | 'UNKNOWN_DEVICE';
  email?: string;
  phone?: string;
  name?: string;
  lastVerificationMethod?: 'SMS' | 'EMAIL';
}

export async function logout() {
  await forgetDevice();
  const url = new URL(BASE_URL);
  url.searchParams.set('redirectUri', authRedirectUri);
  webAuth.logout({returnTo: url.toString()});
}

export async function forgetDevice() {
  await fetch(APP_LOGOUT_URL, {
    credentials: 'include',
  });
}

export async function resetPassword(email: string): Promise<boolean> {
  return new Promise<boolean>(resolve => {
    webAuth.changePassword(
      {
        connection: AUTH0_DB_CONNECTION,
        email,
      },
      error => {
        if (error) {
          return resolve(false);
        }
        return resolve(true);
      }
    );
  });
}

async function signup(token: string): Promise<Response> {
  return await fetch(`${APP_SIGNUP_URI}?state=${state}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'POST',
  });
}

function buildRedirectUri() {
  const localStorageKey = `${state}:redirectUri`;
  const redirectUriFromLocalStorage = localStorage.getItem(localStorageKey);

  const redirectUriFromParams =
    params.get('redirectUri') ?? params.get('redirect_uri');
  if (redirectUriFromParams) {
    // In order to avoid passing uri through multiple redirects
    // persist in local storage
    localStorage.setItem(localStorageKey, redirectUriFromParams);
    return redirectUriFromParams;
  }

  if (redirectUriFromLocalStorage) {
    return redirectUriFromLocalStorage;
  }

  return DEFAULT_APP_AUTHORIZED_REDIRECT_URI;
}

function redirectToApp(url: string) {
  // clear all redirectUri from localStorage
  Object.keys(localStorage).forEach(key => {
    if (key.endsWith(':redirectUri')) {
      localStorage.removeItem(key);
    }
  });

  window.location.replace(url);
}

export function isPasswordLessAuth(email: string | null | undefined): boolean {
  return !(email ?? '').endsWith('@ennabl-test.com');
}
