import React, {useState} from 'react';
import {unwrapResult} from '@reduxjs/toolkit';
import {useDispatch} from 'react-redux';
import {backToPrevScreen, createUser, enterCode, selectScreen} from 'auth/authSlice';
import Button from 'components/Button';
import MobileCodeCommon from 'components/MobileCodeCommon/MobileCodeCommon';
import {useAppSelector} from 'hooks';
import {EnterCodeScreen} from 'auth/authApi';
import commonStyle from 'components/common.module.css';
// import history from 'core/history';
// import {SIGN_UP_USER_INFO} from 'core/routes';
import {maskEmail} from 'utils/maskEmailOrPhone';
import cx from 'clsx';

import style from './VerificationCode.module.css';
import LoginLink from '../LoginLink';
import ContactUs from '../../../components/ContactUs';

const VerificationCode: React.FC = () => {
  const [code, setCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();
  const screen = useAppSelector(selectScreen);

  const [loading, setLoading] = useState(false);

  const {
    resend = () => {},
    email = '',
    backScreen,
  } = screen as EnterCodeScreen;

  const handleClickResend = () => {
    resend();
  };

  const handleSubmit = async (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();

    try {
      const enterCodeResult = await dispatch(
        enterCode({screen: screen as EnterCodeScreen, code})
      );
      const createUserResult = await dispatch(createUser())
      // TODO: fix dispatch typings
      // @ts-ignore
      unwrapResult(enterCodeResult);
      // @ts-ignore
      unwrapResult(createUserResult);
    } catch (e) {
      const errorText = e.message === 'Wrong email or verification code.' ? 'Wrong verification code' : e.message;
      setErrorMessage(errorText);
    }

    setLoading(false);
  };

  const handleClickBack = () => {
    if (backScreen) {
      // history.push(SIGN_UP_USER_INFO); // hardcode hack for routing, rewrite when needed
      dispatch(backToPrevScreen({backScreen}));
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className={style.form}>
        <div className={cx(style.subtitle, style.subtitle_margin)}>
          For security reasons we need to verify your identity, please check your email for a secure code
        </div>
        <div className={cx(commonStyle.text, style.text_margin)}>
          Code sent to email {maskEmail(email)}
        </div>

        <MobileCodeCommon
          type='SIGNUP'
          onResend={handleClickResend}
          value={code}
          onChange={value => setCode(value)}
          errorMessage={errorMessage}
        />
        <Button
          disabled={code.length !== 6}
          loading={loading}
          className={cx(commonStyle.button, style.submit)}
          type='submit'
        >
          Create account
        </Button>
        {backScreen && (
          <Button
            className={cx(commonStyle.button, commonStyle.marginTop)}
            onClick={handleClickBack}
            variant='border'
          >
            BACK
          </Button>
        )}
      </form>
      <LoginLink />
      <ContactUs type='SIGN_UP' />
    </>
  );
};

export default VerificationCode;
