import {Components, Theme} from '@mui/material/styles';
import {paperClasses} from '@mui/material/Paper';
import {listItemIconClasses} from '@mui/material/ListItemIcon';

export const menu = (theme: Theme): Partial<Components> => ({
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        '> svg': {
          width: 16,
          height: 16,
          color: theme.palette.text.secondary,
        },
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      list: {
        display: 'grid',
        gap: theme.spacing(1),
      },
      paper: {
        [`&.${paperClasses.root}`]: {
          marginTop: theme.spacing(2),
          borderRadius: theme.shape.borderRadiusRegular,
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        minWidth: 271,

        [`& .${listItemIconClasses.root}`]: {
          minWidth: 20 + 8,

          '> svg': {
            width: 20,
            height: 20,
          },
        },
      },
      dense: {
        [`& .${listItemIconClasses.root}`]: {
          minWidth: 16 + 8,

          ' > svg': {
            width: 16,
            height: 16,
          },
        },
      },
    },
  },
  MuiBackdrop: {
    styleOverrides: {
      root: {
        background: 'rgba(0, 0, 0, 0.25)',
      },
    }
  }
});
