import {PaletteMode} from '@mui/material';

// import {colorVariablesNext} from 'core/constants';

const defaultPalette = (mode: PaletteMode) => {
  return {
    mode: mode,

    common: {
      black: '#1E1E1E',
      white: '#FFFFFF',
    },
    primary: {
      light: '#6139F9',
      main: '#0000C5',
      dark: '#000093',
    },

    // Secondary - Temporary solution for 'See All button' under charts on Account Managers page.
    // Remove when design is ready.
    secondary: {
      light: '#FFFFFF',
      main: '#F5F8FD',
      dark: '#EEF3FC',
      contrastText: '#0000C5',
    },
    error: {
      light: '#FF8C6F',
      main: '#FC5943',
      dark: '#C22019',
    },
    success: {
      light: '#67EBAD',
      main: '#3DD598',
      dark: '#008751',
      contrastText: '#FFFFFF',
    },
    warning: {
      light: '#FFE751',
      main: '#FFC542',
      dark: '#C78600',
    },
    text: {
      primary: 'rgba(30, 30, 30, 0.87)',
      secondary: 'rgba(130, 135, 176, 0.8)',
      disabled: 'rgba(30, 30, 30, 0.38)',
    },

    divider: 'hsla(217, 68%, 94%, 1)',

    action: {
      fadedOpacity: 0.87,
    },

    // background: {
    //   grey: colorVariablesNext.Background.Grey,
    //   darkGrey: colorVariablesNext.Background.DarkGrey,
    //   white: colorVariablesNext.Background.White,
    // },
  };
};

export default defaultPalette;
