const shape = {
  shape: {
    borderRadiusRegular: 8,
    borderRadiusLarge: 16,
    sectionContainerMaxWidth: {
      lg: 1200,
      sm: 864,
    },
  },
};

export default shape;
