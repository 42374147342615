import React from 'react';
import {Redirect, Route, Router, Switch} from 'react-router-dom';
import '@material/react-text-field/dist/text-field.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {ThemeProvider} from '@mui/material/styles';

import {themeDecisions} from 'core/themes/themeDecisions';
import {analytics} from 'core/Analytics';
import {LOGIN, SIGN_UP} from 'core/routes';
import history from 'core/history';

import Login from 'modules/Login';
import SignUp from 'modules/SignUp';

import Header from 'components/Header';

import ErrorBoundary from './ErrorBoundary';
import style from './App.module.css';

process.env.REACT_APP_ENV_NAME === 'prod' &&
  analytics.init('jbnoc5/login-j8mth');

function App() {
  return (
    <ErrorBoundary>
      <ThemeProvider theme={themeDecisions}>
        <Router history={history}>
          <div className={style.container}>
            <Header />
            <Switch>
              <Route exact path='/'>
                <Redirect to={`${LOGIN}${history.location.search}`} />
              </Route>

              <Route path={LOGIN} component={Login} />
              <Route path={SIGN_UP} component={SignUp} />
            </Switch>
          </div>
        </Router>
      </ThemeProvider>
    </ErrorBoundary>
  );
}
export default App;
