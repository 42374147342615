import {Components, Theme} from '@mui/material/styles';

export const input = (
  theme: Theme
): {
  MuiInputLabel: Components['MuiInputLabel'];
  MuiFilledInput: Components['MuiFilledInput'];
  MuiFormHelperText: Components['MuiFormHelperText'];
  MuiFormLabel: Components['MuiFormLabel'];
  MuiTextField: Components['MuiTextField'];
} => ({
  MuiInputLabel: {
    styleOverrides: {
      // @ts-ignore
      filled: params => {
        const {filled, focused, adornedStart} = params.ownerState.formControl as {
          filled: boolean;
          focused: boolean;
          adornedStart: boolean;
        };

        const shrink = filled || focused;

        const sizeSmall = {
          cursor: 'text',
          transform: 'translate(14px, 10px)',
          ...(adornedStart && {transform: 'translate(43px, 10px)'}),
          ...(shrink && {visibility: 'hidden' as const}),
        };

        return {
          ...theme.typography.subtitle1,
          ...(adornedStart && {transform: 'translate(43px, 16px)'}),
          ...(shrink && {
            ...theme.typography.body3,
            transform: adornedStart ? 'translate(43px, 7px)' : 'translate(14px, 7px)',
          }),
          ...(params.ownerState.size === 'small' && sizeSmall),
        };
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: params => ({
        ...theme.typography.subtitle1,
        backgroundColor: theme.palette.background.grey,
        border: `2px solid transparent`,
        borderRadius: theme.shape.borderRadiusRegular,
        transition: theme.transitions.create(['border-color'], {
          duration: theme.transitions.duration.shortest,
        }),
        padding: theme.spacing(0, 3),
        gap: theme.spacing(3),

        '> svg': {
          width: 16,
          height: 16,
          flexShrink: 0,
          color: theme.palette.text.secondary,
        },

        ':hover': {
          ':not(.Mui-disabled)': {
            backgroundColor: theme.palette.background.grey,

            '> svg': {
              color: theme.palette.text.primary,
            },
          },

          ':not(.Mui-disabled, .Mui-focused, .Mui-error)': {
            border: `2px solid ${theme.palette.divider}`,
          },
        },

        '&.Mui-focused': {
          backgroundColor: theme.palette.background.grey,

          '&:not(.Mui-error)': {
            border: `2px solid ${theme.palette[params.ownerState.color || 'primary'].main}`,
          },

          '> svg': {
            color: theme.palette.text.primary,
          },
        },

        ':before, :after': {
          display: 'none',
        },

        '&.Mui-disabled': {
          backgroundColor: theme.palette.common.white,

          '> svg': {
            color: theme.palette.text.disabled,
          },
        },
      }),
      colorSecondary: {
        backgroundColor: theme.palette.background.grey,

        '> svg': {
          color: theme.palette.text.primary,
        },

        ':hover': {
          ':not(.Mui-disabled)': {
            backgroundColor: theme.palette.background.grey,

            '> svg': {
              color: theme.palette.text.primary,
            },
          },

          ':not(.Mui-disabled, .Mui-focused, .Mui-error)': {
            border: `2px solid ${theme.palette.divider}`,
          },
        },

        '&.Mui-focused': {
          backgroundColor: theme.palette.background.grey,

          '&:not(.Mui-error)': {
            border: `2px solid ${theme.palette.primary.main}`,
          },
        },

        '&.Mui-disabled': {
          backgroundColor: theme.palette.background.grey,
        },
      },
      error: {
        border: `2px solid ${theme.palette.error.main}`,
      },
      input: {
        padding: '22px 0 7px 0',
        height: 'auto',
      },
      inputSizeSmall: {
        padding: '8px 0 8px 0',
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      // @ts-ignore
      root: {
        ...theme.typography.body3,
        margin: theme.spacing(0.5, 3, 0),
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      colorSecondary: params => {
        const {filled, focused} = params.ownerState;
        const shrink = filled || focused;
        return {
          ...(shrink && {
            color: theme.palette.primary.main,
          }),

          '&.Mui-focused:not(.Mui-error)': {
            color: theme.palette.primary.main,
          },
        };
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: 'filled',
    },
  },
});
