import React, {MouseEvent, useState} from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import cx from 'clsx';

import style from '../../Header.module.css';
import burgerOpen from '../../../icons/hamburger.svg';
import {routes} from '../Links';

const BurgerMenu = () => {
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleBurgerClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setShowDrawer(prev => !prev)
  }

  const handleClose = () => {
    setAnchorEl(null);
    setShowDrawer(false)
  };

  const handleItemClick = (to: string) => {
    handleClose();
    window.location.href = to;
  }

  return (
    <>
      <button
        id={'nav-menu-button'}
        onClick={handleBurgerClick}
        className={cx(style.burger, style.burger_white)}
      >
        <img src={burgerOpen} alt='burger-open' />
      </button>

      <Menu
        id='nav-menu'
        className={style.dropdownMenu}
        open={showDrawer}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{'aria-labelledby': 'nav-menu-button'}}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {routes.map(route => (
          <MenuItem
            dense
            key={route.to}
            onClick={() => handleItemClick(route.to)}
          >
            <ListItemIcon>
              <route.Icon />
            </ListItemIcon>
            {route.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default BurgerMenu;