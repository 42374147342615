import React from 'react';
import LogRocket from 'logrocket';

class ErrorBoundary extends React.Component {
  componentDidCatch(error: any) {
    LogRocket.captureException(error);
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
