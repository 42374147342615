import React from 'react';

import style from './HeadLegend.module.css';

const HeadLegend= () => {
  return (
    <div className={style.headContainer}>
      <h2 className={style.head}>Sign up</h2>
      <p className={style.description}>Welcome to ennabl</p>
    </div>
  );
};

export default HeadLegend;
