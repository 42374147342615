import {createSlice, PayloadAction, createAsyncThunk} from '@reduxjs/toolkit';

import type {RootState} from 'store';

import {
  EnterCodeScreen,
  EntryScreen,
  Screen,
  UserInfoInput,
  EnterEmailScreen,
} from './authApi';
import {
  CreateUser,
  entryScreen,
  logout as auth0Logout,
  resetPassword as auth0ResetPassword
} from './auth0';

// Define a type for the slice state
interface AuthState {
  screen: Screen;
  savedUserInfo?: UserInfoInput;
}

// Define the initial state using that type
const initialState: AuthState = {
  screen: entryScreen(),
};

export const logout = createAsyncThunk('auth/logout', async () => {
  await auth0Logout();
  const screen = entryScreen() as EntryScreen;
  return await screen.start();
});

export const startAuth = createAsyncThunk(
  'auth/start',
  async (isSignUp: boolean = false) => {
    const entryScreenBasedOnSignUp = entryScreen(isSignUp);

    if ('start' in entryScreenBasedOnSignUp) {
      return await entryScreenBasedOnSignUp.start();
    }

    return entryScreenBasedOnSignUp;
  }
);

export const login = createAsyncThunk(
  'auth/loginUsingPassword',
  async (props: {screen: EnterEmailScreen; email: string}) => {
    return await props.screen.login(props.email);
  }
);

export const enterCode = createAsyncThunk(
  'auth/enterCode',
  async (props: {screen: EnterCodeScreen; code: string}) => {
    return await props.screen.enter(props.code);
  }
);

export const createUser = createAsyncThunk(
  'auth/createUser',
  async () => {
    return await CreateUser();
  }
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (props: {email: string}) => {
    await auth0ResetPassword(props.email);
    await auth0Logout();
    const screen = entryScreen() as EntryScreen;
    return await screen.start();
  }
);

export const backToPrevScreen = createAsyncThunk(
  'auth/backToPrevScreen',
  async (props: {backScreen: Promise<Screen>}) => {
    return props.backScreen;
  }
);

export const authSliceFactory = (initialState: any) =>
  createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: builder => {
      builder
        .addCase(
          startAuth.fulfilled,
          (state, action: PayloadAction<Screen>) => {
            state.screen = action.payload;
          }
        )
        .addCase(login.fulfilled, (state, action: PayloadAction<Screen>) => {
          state.screen = action.payload;
        })
        .addCase(
          enterCode.fulfilled,
          (state, action: PayloadAction<Screen>) => {
            state.screen = action.payload;
          }
        )
        .addCase(logout.fulfilled, (state, action: PayloadAction<Screen>) => {
          state.screen = action.payload;
        })
        .addCase(
          resetPassword.fulfilled,
          (state, action: PayloadAction<Screen>) => {
            state.screen = action.payload;
          }
        )
        .addCase(backToPrevScreen.fulfilled, (state, action) => {
          state.screen = action.payload;
        });
    },
  });

// Other code such as selectors can use the imported `RootState` type
export const selectScreen = (state: RootState) => state.auth.screen;
export default authSliceFactory(initialState).reducer;
