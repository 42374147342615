import {Theme} from '@mui/material/styles';

import MuiLink from './link';
import {input} from './input';
import {menu} from './menu';

export const overrides = (theme: Theme) => {
  const inputOverrides = input(theme);
  const linkOverrides = MuiLink(theme);
  const menuOverrides = menu(theme);

  return Object.assign(
    inputOverrides,
    linkOverrides,
    menuOverrides,
  );
};
