import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import ReactCodeInput from '@acusti/react-code-input';
import commonStyle from 'components/common.module.css';
import cx from 'clsx';

import style from './MobileCodeCommon.module.css';

function getWindowDimensions() {
  const {innerWidth: width, innerHeight: height} = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const commonInputStyles = {
  fontFamily: 'Inter',
  fontWeight: 700,
  boxShadow: 'none',
  margin: '0',
  borderRadius: '4px',
  boxSizing: 'border-box',
  color: 'rgba(30, 30, 30, 0.87)',
  backgroundColor: "#F5F8FD",
  border: 'none',
  height: '56px',
} as React.CSSProperties;

const smallInputStyles = {
  ...commonInputStyles,
  width: '41px',
  fontSize: '20px',
  padding: '0 0 0 10px',
} as React.CSSProperties;

const largeInputStyles = {
  ...commonInputStyles,
  width: '43px',
  fontSize: '20px',
  padding: '0 0 0 15px',
} as React.CSSProperties;

interface MobileCodeCommonProps {
  onResend: () => void;
  errorMessage?: string;
  value: string;
  onChange: (value: string) => void;
  type: 'LOGIN' | 'SIGNUP';
  hideResend?: boolean;
}

const MobileCodeCommon: React.FC<MobileCodeCommonProps> = ({
  onResend,
  errorMessage,
  value,
  onChange,
  type,
  hideResend
}) => {
  const [timer, setTimer] = useState(60);
  const [isTimerStarted, setIsTimerStarted] = useState(false);
  const [inputStyles, setInputStyles] = useState<React.CSSProperties>();

  const inputWrapperRef = useRef<HTMLDivElement>(null);

  const dimensions = useWindowDimensions();

  useEffect(() => {
    if (dimensions.width < 500) {
      setInputStyles(smallInputStyles);
    } else {
      setInputStyles(largeInputStyles);
    }
  }, [dimensions.width]);

  useEffect(() => {
    let intervalID: any;

    if (isTimerStarted) {
      intervalID = setInterval(() => {
        setTimer(s => s - 1);

        if (timer === 0) {
          setIsTimerStarted(false);
          setTimer(60);
        }
      }, 1000);
    } else {
      clearInterval(intervalID);
    }

    return () => clearInterval(intervalID);
  }, [isTimerStarted, timer]);

  // It disables Safari autofill suggestions.
  // Even tho each input has attribute autocomplete="off", 
  // Safari still suggests to autofill because of some reasons (inputmode, min, max, type etc.)
  useLayoutEffect(() => {
    const inputWrapperNode = inputWrapperRef.current;

    if (inputWrapperNode) {
      const inputs = inputWrapperNode.querySelectorAll('input');

      inputs.forEach((input, index) => {
        input.setAttribute('name', `search-${index}`);
      });
    }
  }, []);

  const handleClickResend = () => {
    if (!isTimerStarted) {
      onResend();
      setIsTimerStarted(true);
    }
  };

  return (
    <div>
      {type === 'SIGNUP' &&
        <div className={commonStyle.subtitle}>Please enter security code</div>
      }

      <div
        data-private='redact'
        className={type === 'SIGNUP' ? style.signUpCodeInputWrapper : style.loginCodeInputWrapper}
        ref={inputWrapperRef}
      >
        <ReactCodeInput
          type='number'
          fields={6}
          inputMode='numeric'
          name='sms-code'
          className={style.codeInputContainer}
          inputStyle={inputStyles}
          value={value}
          onChange={onChange}
        />
      </div>

      {errorMessage &&
        <div className={cx(commonStyle.error, style.errorMessage)}>
          {errorMessage}
        </div>
      }
      
    { !hideResend &&
      <div className={`${style.resend} ${type === 'SIGNUP' ? style.signup_margin : style.login_margin}`}>
        Didn't get a code?
        <span
          onClick={handleClickResend}
          className={cx(commonStyle.link, commonStyle.link_blue, isTimerStarted && style.disabled)}
        >
          Resend {isTimerStarted ? `code in ${timer} sec.` : ''}
        </span>
      </div>
      }

      { hideResend && 
        <div className={`${style.resend} ${style.login_margin}`}>
        </div> 
      }
    </div>
  );
};

export default MobileCodeCommon;
