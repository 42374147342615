import {forwardRef, useCallback} from 'react';
import type {FieldRenderProps} from 'react-final-form';
import {SetRequired} from 'type-fest';

import TextFieldComponent, {TextFieldProps as TextFieldComponentProps} from 'components/form-next/TextField';

export type TextFieldProps = FieldRenderProps<any, HTMLElement> & TextFieldComponentProps;

/**
 * This component belongs to final-form
 */
const TextField = forwardRef<HTMLDivElement, TextFieldProps>(
  (
    {
      className,
      withCheckedIcon = false,
      // reserve space for helperText
      helperText, // = <span>&nbsp;</span>,
      formErrorText = '',
      fullWidth = false,
      input,
      meta,
      errorIsDirtySinceLastSubmit = false,
      onChange,
      inputProps = {},
      ...props
    },
    ref
  ) => {
    const shouldShowError = (meta.touched && (meta.error || meta.submitError)) || formErrorText.length;

    const handleChange = useCallback<SetRequired<TextFieldProps, 'onChange'>['onChange']>(
      e => {
        if (onChange) {
          onChange(e);
        }

        input.onChange(e);
      },
      [onChange, input]
    );

    return (
      <TextFieldComponent
        helperTextRequired
        id={input.name}
        {...input}
        // value can be overwritten by props
        value={input.value || ''}
        fullWidth={fullWidth}
        error={shouldShowError}
        helperText={shouldShowError ? meta.error || meta.submitError || formErrorText : helperText}
        withCheckedIcon={withCheckedIcon && meta.valid && input.value}
        inputProps={{
          ...inputProps,
          onChange: handleChange,
        }}
        {...props}
        ref={ref}
      />
    );
  }
);

export default TextField;
