import React from 'react';

import style from './Header.module.css';
import Links from './components/Links';
import Logo from './components/Logo';
import BurgerMenu from './components/BurgerMenu/BurgerMenu';

const Header = () => {
  return (
    <div className={style.container}>
      <Logo />

      {/* Desktop*/}
      <div className={style.nav}>
        <Links />
      </div>

      {/* Mobile */}
      <BurgerMenu />
    </div>
  );
};

export default Header;
