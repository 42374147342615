import {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {useDispatch} from 'react-redux';
import {useAppSelector} from 'hooks';
import {selectScreen, startAuth} from 'auth/authSlice';
import {ScreenType} from 'auth/authApi';
import appStyle from 'App.module.css';
import Loader from 'components/Loader';
import ContactUs from 'components/ContactUs';
import FirstTimeAuth from 'modules/Login/FirstTimeAuth';
import cx from 'clsx';

import VerificationCode from './VerificationCode';
import style from './Login.module.css';

function renderForm(screenType: ScreenType) {
  if (screenType === 'ENTER_CODE') {
    return <VerificationCode />;
  } else {
    return <FirstTimeAuth />;
  }
}

const Login = () => {
  const dispatch = useDispatch();
  const screen = useAppSelector(selectScreen);

  useEffect(() => {
    if (screen.screenType === 'ENTRY_SCREEN') {
      dispatch(startAuth(false));
    }
  }, [screen.screenType, dispatch]);

  return (
    <div className={cx(appStyle.routesContainer, style.loginContainer)}>
      <Helmet>
        <title>Login - Ennabl</title>
      </Helmet>

      {['ENTRY_SCREEN', 'AUTHORIZED'].includes(screen.screenType) ? (
        <div className={appStyle.loaderContainer}>
          <Loader />
        </div>
      ) : (
        <>
          {renderForm(screen.screenType)}

          <ContactUs type='LOGIN' />
        </>
      )}
    </div>
  );
};

export default Login;
