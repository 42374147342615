import {forwardRef, useState} from 'react';
import {uniqueId} from 'lodash/fp';
import {styled} from '@mui/material/styles';
import MuiTextField, {TextFieldProps as MuiTextFieldProps} from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import {Merge} from 'type-fest';
import {inputBaseClasses} from '@mui/material';

import CheckIcon from 'components/icons/outlinedCheck.svg';

export {inputBaseClasses};

export type TextFieldProps = Merge<
  MuiTextFieldProps,
  {
    /**
     * prevents input layout height from jumping on validation
     * text appearing by reserving dummy space for helper text
     */
    helperTextRequired?: boolean;
    /**
     * Mark filled field by checked icon
     */
    withCheckedIcon?: boolean;
  }
>;

const SInputAdornment = styled(InputAdornment)`
  margin-left: 10px;
`;

const TextField = forwardRef<HTMLDivElement, TextFieldProps>(
  (
    {
      id,
      className,
      autoComplete = 'off',
      autoFocus = false,
      disabled = false,
      error = false,
      fullWidth = false,
      multiline = false,
      required = false,
      placeholder,
      FormHelperTextProps,
      InputLabelProps,
      InputProps,
      inputRef,
      SelectProps,
      helperText,
      helperTextRequired,
      withCheckedIcon,
      ...props
    },
    ref
  ) => {
    const [inputName] = useState(id || uniqueId('text-field-'));
    const helperTextOrDummy = helperTextRequired ? helperText || <span>&nbsp;</span> : helperText;
    const withHelperText = Boolean(helperText || helperTextRequired);

    return (
      <MuiTextField
        autoFocus={autoFocus}
        fullWidth={fullWidth}
        multiline={multiline}
        inputRef={inputRef}
        ref={ref}
        id={`${inputName}-input`}
        aria-describedby={`${inputName}-helper-text`}
        aria-labelledby={`${inputName}-label`}
        autoComplete={autoComplete}
        error={error}
        required={required}
        disabled={disabled}
        placeholder={placeholder}
        helperText={withHelperText ? helperTextOrDummy : undefined}
        InputLabelProps={{
          ...InputLabelProps,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore input placeholder hidden on data-shrink={false}
          ...(placeholder ? {'data-shrink': true} : {}),
        }}
        InputProps={{
          endAdornment: withCheckedIcon && (
            <SInputAdornment position='end'>
              {/*// @ts-ignore*/}
              <CheckIcon width={20} height={20} />
            </SInputAdornment>
          ),
          ...InputProps,
        }}
        {...props}
      />
    );
  }
);

export default TextField;
