import React from 'react';
import commonStyle from 'components/common.module.css';
import cx from 'clsx';

import style from './ContactUs.module.css';

interface Props {
  type: 'LOGIN' | 'SIGN_UP';
}

const ContactUs: React.FC<Props> = ({type}) => {
  return (
    <>
      <div className={cx(commonStyle.centerLink, type === 'SIGN_UP' ? style.tinyMargin : style.smallMargin)}>
        Having trouble logging in?{' '}
        <a
          className={cx(commonStyle.link, commonStyle.link_blue)}
          target='_blank'
          rel='noreferrer nofollow'
          href={'mailto:info@ennabl.com'}
        >
          Contact Us
        </a>
      </div>
      <div className={cx(commonStyle.centerLink, style.tinyMargin)}>
        <a
          className={cx(commonStyle.link, commonStyle.link_grey)}
          target='_blank'
          rel='noreferrer nofollow'
          href={'https://www.notion.so/ennabl/Website-Privacy-Policy-3c00ebf5817e4efa9bc3ea4bcc17c815'}
        >
          Privacy Policy
        </a>
      </div>
    </>
  );
};

export default ContactUs;
