export function maskEmail(email: string) {
  const parts = email.split('@');
  const name = parts.shift();
  const emailEnding = parts.shift()?.split('.') || ['', ''];
  const emailDomain = emailEnding?.shift();

  return `${
    name && name.length <= 4 ? name[0] : name?.slice(0, name?.length - 4)
  }••••@${
    emailDomain && emailDomain.length <= 3 ? emailDomain[0] : emailDomain?.slice(0, emailDomain.length - 3)
  }••.${emailEnding?.join('')}`;
}

export function maskPhone(phone: string) {
  return `•••-•••-${phone.substr(phone.length - 4)}`;
}
