import React from 'react';

import style from '../Header.module.css';

import logo from '../icons/logo.svg';

const Logo = () => {
  return (
    <a className={style.logoContainer} href={'https://ennabl.com/'}>
      <img className={style.logo} src={logo} alt='logo' />
    </a>
  );
};

export default Logo;