import React from 'react';

import style from './Loader.module.css';

interface Props {
  className?: string;
}

const Loader: React.FC<Props> = ({className}) => {
  return <div className={`${style.loader} ${className}`} />;
};

export default Loader;
